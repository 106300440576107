<template>
	<div class="databoard">
		<div class="main">
			<div class="main-title">
				<div class="main-title-01">今日实时数据</div>
				<div class="main-title-02">{{ date5 }} 更新</div>
			</div>
			<div class="main-con1">
				<div class="main-con1-item">
					<div class="main-con1-item-txt1">实时在馆人数</div>
					<div class="main-con1-item-txt2">{{ todayData.nowCount }}</div>
				</div>
				<div class="main-con1-item">
					<div class="main-con1-item-txt1">小程序累计用户数</div>
					<div class="main-con1-item-txt2">{{ todayData.totalUser }}</div>
				</div>
				<div class="main-con1-item">
					<div class="main-con1-item-txt1">今日核销人数</div>
					<div class="main-con1-item-txt2">{{ todayData.checkUser }}</div>
				</div>
				<div class="main-con1-item">
					<div class="main-con1-item-txt1">今日预约数</div>
					<div class="main-con1-item-txt2">{{ todayData.resUser }}</div>
				</div>
			</div>
		</div>
		<div class="main mtop">
			<div class="main-title">
				<div class="main-title-01">团队实时数据</div>
				<div class="main-title-02">{{ date5 }} 更新</div>
			</div>
			<div class="main-con2">
				<div class="main-con2-item">
					<div class="icon">
						<img src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627536253-61023b7da1e4b.png" alt="">
					</div>
					<div class="text1">累计接待</div>
					<div class="text1">团队人数</div>
					<div class="text2">{{ groupData.checkNums }}</div>
				</div>
				<div class="main-con2-item">
					<div class="icon">
						<img src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627536253-61023b7dc2b2a.png" alt="">
					</div>
					<div class="text1 bd">累计团队</div>
					<div class="text1 bd">预约个数</div>
					<div class="text2">{{ groupData.nums }}</div>
				</div>
				<div class="main-con2-item">
					<div class="icon">
						<img src="https://oss.mcloud.moveclub.cn/mpmsalpha/20210729/1627536253-61023b7de89c0.png" alt="">
					</div>
					<div class="text1">累计预约</div>
					<div class="text1">团队人数</div>
					<div class="text2">{{ groupData.peoples }}</div>
				</div>
			</div>
		</div>
		
		<div class="main mtop">
			<div class="main-title">
				<div class="main-title-01">累计数据分析</div>
				<div class="main-title-02">{{ date5 }} 更新</div>
			</div>
			<div class="main-con3">
				<div class="main-con3-item bd2">
					<div class="text1">累计预约人数</div>
					<div class="text2">{{ totalData.resNums }}</div>
				</div>
				<div class="main-con3-item">
					<div class="text1">累计参观人数</div>
					<div class="text2">{{ totalData.checkNums }}</div>
				</div>
			</div>
			<div class="main-con4">
				<div id="myChart" :style="{width: '45%', height: '200px'}"></div>
				<div class="right">
					<div class="right-item">
						<div class="right-item-01">	
						</div>
						<div class="right-item-02">
							<div class="text1">男性人数</div>
							<div class="text2">
								<span class="text2-s" v-if="totalData != ''">{{ totalData.gender['1'].value }}</span>人<span class="text2-l">|</span><span class="text2-s" v-if="totalData != ''">{{ totalData.gender['1'].percent }}%</span></div>
						</div>
					</div>
					<div class="right-item">
						<div class="right-item-04">	
						</div>
						<div class="right-item-02">
							<div class="text1">女性人数</div>
							<div class="text2">
								<span class="text2-s" v-if="totalData != ''">{{ totalData.gender['2'].value }}</span>人<span class="text2-l">|</span><span class="text2-s" v-if="totalData != ''">{{ totalData.gender['2'].percent }}%</span></div>
						</div>
					</div>
				</div>
			</div>
			<div class="main-con5">
				<div class="title">预约参观人群年龄段分布</div>
				<div class="percentBox">
					<template v-for="(item, index) in totalData.detail">
						<div v-if="item.percent != 0" :key="index" :style="{ width: item.percent+'%', background: item.color, margin: '0 1px' }" class="percentBox-item" ></div>
					</template>
					
					<!-- <div class="percentBox-item"></div>
					<div class="percentBox-item"></div>
					<div class="percentBox-item"></div>
					<div class="percentBox-item"></div> -->
				</div>
				<div class="tuli">
					<div class="tuli-item" v-for="(item, index) in totalData.detail" :key="index">
						<div class="tuli-item-left">
							<div class="tag" :style="{ background: item.color}"></div>
							<div class="text1">{{ item.name }}</div>
						</div>
						<div class="tuli-item-right">
							<div class="text1"><span>{{ item.value }}</span>人</div>
							<div class="text2">|</div>
							<div class="text3">{{ item.percent }}%</div>
						</div>
					</div>
					<!-- <div class="tuli-item">
						<div class="tuli-item-left">
							<div class="tag"></div>
							<div class="text1">14岁年龄段以下</div>
						</div>
						<div class="tuli-item-right">
							<div class="text1"><span>14,000</span>人</div>
							<div class="text2">|</div>
							<div class="text3">63%</div>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from '@/api/api'
export default{
	data(){
		return {
			date5: '',
			
			todayData: {},
			groupData: {},
			totalData: '',
		}
	},
	mounted() {
		let date = (new Date()).getTime()
		this.date5 = this.forMartTime2(date)
		
		this.getDashboard()
		
	},
	methods: {
		getDashboard(){
			var _this = this
			api.getDashboard().then(r => {
				_this.todayData = r.data.data.today
				_this.groupData = r.data.data.group
				// var count = Number(r.data.data.total.gender['1'].value) + Number(r.data.data.total.gender['2'].value)
				_this.totalData = r.data.data.total
				
				var arr = []
				arr.push(r.data.data.total.gender['1'].value)
				arr.push(r.data.data.total.gender['2'].value)
				_this.drawLine(arr);
				
				// _this.resData = r.data.data.doors
			})
		},
		forMartTime2(date){
		  let time  = new Date(date)
		  let y = time.getFullYear()
		  let M = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
		  let d = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
		  let h = time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
		  let m = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
		  let s = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
		  return `${M}-${d} ${h}:${m}`
		},
		drawLine(data){
			// 基于准备好的dom，初始化echarts实例
			var myChart = this.$echarts.init(document.getElementById('myChart'))
			// 绘制图表
			var option = {
				title: { text: '' },
				color: ['#B78B52', '#4A8F82'],
				// legend: {
				// 	top: '5%',
				// 	// left: 'center',
				// 	right: '0'
				// },
				// tooltip: {
				// 		trigger: 'item'
				// },
				 graphic: [{ //环形图中间添加文字
						type: 'text', //通过不同top值可以设置上下显示
						left: 'center',
						top: '45%',
						style: {
								text: '预约参观人'+'\n'+'群性别比',
								textAlign: 'center',
								fill: '#888C96', //文字的颜色
								width: 30,
								height: 30,
								fontSize: '80%',
								fontFamily: "Microsoft YaHei"
						}
				}],
				
				series: [{
						name: '',
						type: 'pie',
						radius: ['60%', '100%'],
						avoidLabelOverlap: false,
						hoverAnimation:false,
						label: {
								show: false,
								position: 'center'
						},
						// emphasis: {
						// 		label: {
						// 				show: false,
						// 				fontSize: '40',
						// 				fontWeight: 'bold'
						// 		}
						// },
						labelLine:{
						    show: false
						},
						data: data
				}]
			}
			// myChart.setOption(option);
			myChart.setOption(option);
			window.addEventListener("resize",function(){
			    myChart.resize();
			});
		}
	}
}
</script>

<style lang="less" scoped>
.databoard{
	width: 100%;
	box-sizing: border-box;
	padding: 30px 20px;
	.mtop{
		margin-top: 20px;
	}
	.main{
		background: #FFFFFF;
		border-radius: 8px;
		box-sizing: border-box;
		padding: 30px 24px;
		.main-title{
			display: flex;
			align-items: center;
			justify-content: space-between;
			.main-title-01{
				font-family: PingFangSC-Medium;
				font-size: 4vw;
				color: rgba(0,0,0,0.85);
				letter-spacing: 0.58px;
			}
			.main-title-02{
				font-family: PingFangSC-Regular;
				font-size: 3vw;
				color: rgba(0,0,0,0.45);
				letter-spacing: 0.39px;
			}
		}
		.main-con1{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 10px;
			.main-con1-item{
				width: 48%;
				border: 1px solid #CCCCCC;
				border-radius: 8px;
				text-align: left;
				box-sizing: border-box;
				padding: 10px;
				margin:5px 0;
				.main-con1-item-txt1{
					font-size: 3vw;
				}
				.main-con1-item-txt2{
					margin-top: 10px;
					font-size: 4vw;
					color: rgba(0,0,0,0.85);
					font-weight: bold;
				}
			}
		}
		.main-con2{
			display: flex;
			justify-content: space-between;
			margin-top: 25px;
			.main-con2-item{
				text-align: center;
				width: 33%;
				.icon{
					width: 35%;
					margin: auto;
					margin-bottom: 10px;
					img{
						width: 100%;
						display: block;
					}
				}
				.text1{
					font-size: 3vw;
					color: #888C96;
					letter-spacing: 0;
					
				}
				.bd{
					border-left: 1px solid #DDDDDD;
					border-right: 1px solid #DDDDDD;
				}
				.text2{
					margin-top: 10px;
					font-size: 4vw;
					color: rgba(0,0,0,0.85);
					letter-spacing: 0;
					font-weight: bolder;
				}
			}
		}
		.main-con3{
			display: flex;
			justify-content: space-between;
			margin-top: 25px;
			border-bottom: 1px solid #dddddd;
			padding-bottom: 25px;
			.bd2{
				border-right: 1px solid #dddddd;
			}
			.main-con3-item{
				width: 50%;
				box-sizing: border-box;
				.text1{
					font-size: 3vw;
					color: #888C96;
					letter-spacing: 0;
				}
				.text2{
					font-size: 6vw;
					color: rgba(0,0,0,0.85);
					font-weight: bolder;
					margin-top: 10px;
				}
			}
		}
		.main-con4{
			display: flex;
			border-bottom: 1px solid #dddddd;
			.right{
				box-sizing: border-box;
				padding-left: 15px;
				flex-grow: 1;
				.right-item{
					display: flex;
					margin-top: 40px;
					.right-item-01{
						width: 3.7vw;
						height: 3.7vw;
						background: #B78B52;
						border-radius: 4px;
					}
					.right-item-04{
						width: 3.7vw;
						height: 3.7vw;
						background: #4A8F82;
						border-radius: 4px;
					}
					.right-item-02{
						text-align: left;
						margin-left: 10px;
						.text1{
							font-size: 3vw;
							color: rgba(0,0,0,0.45);
							letter-spacing: 0;
						}
						.text2{
							font-size: 3vw;
							color: rgba(0,0,0,0.45);
							.text2-s{
								font-size: 4vw;
								color: rgba(0,0,0,0.85);
							}
							.text2-l{
								margin: 0 6px;
							}
						}
					}
					
				}
			}
		}
		.main-con5{
			margin-top: 20px;
			.title{
				text-align: left;
				font-family: PingFangSC-Regular;
				font-size: 3vw;
				color: #888C96;
			}
			.percentBox{
				display: flex;
				justify-content: space-between;
				margin-top: 20px;
				margin-bottom: 20px;
				.percentBox-item{
					width: 10%;
					// background: red;
					height: 3vw;
					&:nth-child(1){
						border-radius: 10px 0 0 10px;
					}
					&:last-child{
						border-radius: 0 10px 10px 0;
					}
				}
			}
			.tuli{
				.tuli-item{
					display: flex;
					align-items: center;
					justify-content: space-between;
					background: #F7F4ED;
					border-radius: 8px;
					box-sizing: border-box;
					padding: 10px;
					margin-top: 10px;
					.tuli-item-left{
						display: flex;
						align-items: center;
						.tag{
							width: 3vw;
							height: 3vw;
							// background: #B78B52;
							border-radius: 3px;
						}
						.text1{
							font-size: 3vw;
							color: rgba(0,0,0,0.45);
							margin-left: 6px;
						}
					}
					.tuli-item-right{
						display: flex;
						align-items: center;
						.text1{
							font-size: 3vw;
							color: rgba(0,0,0,0.45);
							span{
								font-size: 4vw;
								color: rgba(0,0,0,0.85);
								margin-right: 3px;
							}
						}
						.text2{
							margin: 0 10px;
							font-size: 3vw;
						}
						.text3{
							font-size: 4vw;
							color: rgba(0,0,0,0.85);
						}
					}
				}
			}
		}
	}
}

</style>
